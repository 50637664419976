import React from "react"

export default function Cabinets() {
  return (
    <g id="Cabinet_Doors" data-name="Cabinet Doors">
      <g>
        <rect
          x="766.37"
          y="402.87"
          width="163.73"
          height="308.12"
          fill="#38383a"
        />
        <polygon
          points="775.1 412.07 921.36 412.07 921.36 701.79 903.9 683.4 906.08 428.17 790.38 428.17 775.1 412.07"
          fill="#252426"
        />
        <polygon
          points="921.36 701.79 775.1 701.79 775.1 412.07 792.56 430.46 790.38 685.7 906.08 685.7 921.36 701.79"
          fill="#5c5553"
        />
      </g>
      <g>
        <rect
          x="594.64"
          y="402.87"
          width="163.73"
          height="308.12"
          fill="#38383a"
        />
        <polygon
          points="603.37 412.07 749.64 412.07 749.64 701.79 732.17 683.4 734.36 428.17 618.65 428.17 603.37 412.07"
          fill="#252426"
        />
        <polygon
          points="749.64 701.79 603.37 701.79 603.37 412.07 620.84 430.46 618.65 685.7 734.36 685.7 749.64 701.79"
          fill="#5c5553"
        />
      </g>
      <g>
        <rect
          x="171.91"
          y="402.87"
          width="163.73"
          height="308.12"
          fill="#38383a"
        />
        <polygon
          points="180.65 412.07 326.91 412.07 326.91 701.79 309.44 683.4 311.63 428.17 195.93 428.17 180.65 412.07"
          fill="#252426"
        />
        <polygon
          points="326.91 701.79 180.65 701.79 180.65 412.07 198.11 430.46 195.93 685.7 311.63 685.7 326.91 701.79"
          fill="#5c5553"
        />
      </g>
      <g>
        <rect
          x="0.19"
          y="402.87"
          width="163.73"
          height="308.12"
          fill="#38383a"
        />
        <polygon
          points="8.92 412.07 155.18 412.07 155.18 701.79 137.72 683.4 139.9 428.17 24.2 428.17 8.92 412.07"
          fill="#252426"
        />
        <polygon
          points="155.18 701.79 8.92 701.79 8.92 412.07 26.38 430.46 24.2 685.7 139.9 685.7 155.18 701.79"
          fill="#5c5553"
        />
      </g>
      <g>
        <rect
          x="343.64"
          y="432.81"
          width="243"
          height="278.23"
          fill="#38383a"
        />
        <polygon
          points="354.99 441.12 576.77 441.12 576.77 702.74 550.29 686.13 553.6 455.65 378.16 455.65 354.99 441.12"
          fill="#252426"
        />
        <polygon
          points="576.77 702.74 354.99 702.74 354.99 441.12 381.47 457.73 378.16 688.2 553.6 688.2 576.77 702.74"
          fill="#5c5553"
        />
      </g>
    </g>
  )
}
