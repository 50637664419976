import React from "react"

export default function Backsplash() {
  return (
    <React.Fragment>
      <g id="Layer_5" data-name="Layer 5">
        <rect x="0.04" y="198.08" width="929" height="174" fill="#555b64" />
      </g>
      <g id="Tile">
        <g>
          <g>
            <g>
              <rect
                x="871.48"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M967.18,233.76H962c-.75.31-1.79.76-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37,37,0,0,0-5.83,6.92,23,23,0,0,0-3.26,6.93,20.69,20.69,0,0,0-.68,5.56l-.08.06a20.81,20.81,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.07,41.07,0,0,0,6.8-9.29c.4-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M939.9,261a20.69,20.69,0,0,0-.68-5.56,23,23,0,0,0-3.26-6.93,37,37,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.6-2.24-1.05-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.07,41.07,0,0,0,6.8,9.29,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.81,20.81,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M940,261.12a20.81,20.81,0,0,0-5.58.51,22.85,22.85,0,0,0-7,3.05,36.62,36.62,0,0,0-7.09,5.62,41.24,41.24,0,0,0-6.8,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.79-.75,3-1.35a41.77,41.77,0,0,0,9.5-6.52,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.69,20.69,0,0,0,.68-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M959.37,270.3a36.62,36.62,0,0,0-7.09-5.62,22.85,22.85,0,0,0-7-3.05,20.81,20.81,0,0,0-5.58-.51l.08.06a20.69,20.69,0,0,0,.68,5.56,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.77,41.77,0,0,0,9.5,6.52c1.2.6,2.24,1,3,1.35h5.16v-6.88c-.29-.6-.59-1.19-1-2A41.24,41.24,0,0,0,959.37,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="871.48"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M967.18,291.11H962c-.75.31-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,36.39,36.39,0,0,0-5.83,6.92,22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.4,20.4,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41,41,0,0,0,6.8-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M939.9,318.39a20.71,20.71,0,0,0-.68-5.57A22.91,22.91,0,0,0,936,305.9a36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.05-3-1.36h-5.16V298c.28.58.61,1.25,1,2a41,41,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.4,20.4,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M940,318.47a20.81,20.81,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41.24,41.24,0,0,0-6.8,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51A36.39,36.39,0,0,0,936,331a22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M959.37,327.65a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.81,20.81,0,0,0-5.58-.51l.08,0a20.71,20.71,0,0,0,.68,5.57A22.91,22.91,0,0,0,943.7,331a36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1.06,3,1.36h5.16v-6.88c-.29-.6-.59-1.19-1-2A41.24,41.24,0,0,0,959.37,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="871.48"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M967.18,348.46H962c-.75.3-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,36.39,36.39,0,0,0-5.83,6.92,22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.4,20.4,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.24,41.24,0,0,0,6.8-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M939.9,375.74a20.71,20.71,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92,36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.06-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.24,41.24,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.4,20.4,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M940,375.82a20.4,20.4,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41,41,0,0,0-6.8,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.31,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M959.37,385a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.4,20.4,0,0,0-5.58-.51l.08.05a20.71,20.71,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1,3,1.36h5.16v-6.89c-.29-.6-.59-1.19-1-2A41,41,0,0,0,959.37,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
          <g>
            <g>
              <rect
                x="813.79"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M909.48,233.76h-5.15c-.75.31-1.79.76-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37,37,0,0,0-5.83,6.92,23,23,0,0,0-3.26,6.93,20.69,20.69,0,0,0-.68,5.56l-.08.06a20.84,20.84,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.07,41.07,0,0,0,6.8-9.29c.39-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M882.21,261a21.11,21.11,0,0,0-.68-5.56,23.22,23.22,0,0,0-3.26-6.93,37.27,37.27,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.6-2.23-1.05-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.49,41.49,0,0,0,6.79,9.29,36.77,36.77,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.88,20.88,0,0,0,5.59.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M882.29,261.12a20.88,20.88,0,0,0-5.59.51,22.78,22.78,0,0,0-7,3.05,36.56,36.56,0,0,0-7.1,5.62,41.65,41.65,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87H860c.75-.3,1.78-.75,3-1.35a41.26,41.26,0,0,0,9.5-6.52,36.6,36.6,0,0,0,5.84-6.92,23.15,23.15,0,0,0,3.26-6.92,21.11,21.11,0,0,0,.68-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M901.68,270.3a36.62,36.62,0,0,0-7.09-5.62,22.85,22.85,0,0,0-7-3.05,20.84,20.84,0,0,0-5.58-.51l.08.06a20.69,20.69,0,0,0,.68,5.56,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.77,41.77,0,0,0,9.5,6.52c1.2.6,2.24,1,3,1.35h5.15v-6.88c-.28-.6-.58-1.19-1-2A41.24,41.24,0,0,0,901.68,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="813.79"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M909.48,291.11h-5.15c-.75.31-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51A36.39,36.39,0,0,0,886,305.9a22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41,41,0,0,0,6.8-9.3c.39-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M882.21,318.39a21.13,21.13,0,0,0-.68-5.57,23.15,23.15,0,0,0-3.26-6.92,36.6,36.6,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.61-2.23-1.05-3-1.36h-5.16V298c.28.58.61,1.25,1,2a41.39,41.39,0,0,0,6.79,9.3,36.77,36.77,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.47,20.47,0,0,0,5.59.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M882.29,318.47a20.88,20.88,0,0,0-5.59.51,23,23,0,0,0-7,3,36.77,36.77,0,0,0-7.1,5.63,41.65,41.65,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87H860c.75-.3,1.78-.75,3-1.36a41,41,0,0,0,9.5-6.51,36.6,36.6,0,0,0,5.84-6.92,23.15,23.15,0,0,0,3.26-6.92,21.13,21.13,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M901.68,327.65a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.84,20.84,0,0,0-5.58-.51l.08,0a20.71,20.71,0,0,0,.68,5.57A22.91,22.91,0,0,0,886,331a36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1.06,3,1.36h5.15v-6.88c-.28-.6-.58-1.19-1-2A41.24,41.24,0,0,0,901.68,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="813.79"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M909.48,348.46h-5.15c-.75.3-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,36.39,36.39,0,0,0-5.83,6.92,22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.24,41.24,0,0,0,6.8-9.3c.39-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M882.21,375.74a21.13,21.13,0,0,0-.68-5.57,23.15,23.15,0,0,0-3.26-6.92,36.6,36.6,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.61-2.23-1.06-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.65,41.65,0,0,0,6.79,9.3,36.77,36.77,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.47,20.47,0,0,0,5.59.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M882.29,375.82a20.47,20.47,0,0,0-5.59.51,23,23,0,0,0-7,3,36.77,36.77,0,0,0-7.1,5.63,41.39,41.39,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87H860c.75-.31,1.78-.75,3-1.36a41,41,0,0,0,9.5-6.51,36.6,36.6,0,0,0,5.84-6.92,23.15,23.15,0,0,0,3.26-6.92,21.13,21.13,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M901.68,385a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.43,20.43,0,0,0-5.58-.51l.08.05a20.71,20.71,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1,3,1.36h5.15v-6.89c-.28-.6-.58-1.19-1-2A41,41,0,0,0,901.68,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <rect
                x="756.1"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M851.79,233.76h-5.15c-.75.31-1.79.76-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37.71,37.71,0,0,0-5.84,6.92,23.22,23.22,0,0,0-3.25,6.93,20.69,20.69,0,0,0-.68,5.56l-.08.06a20.84,20.84,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.4,41.4,0,0,0,6.8-9.29c.39-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M824.51,261a20.61,20.61,0,0,0-.67-5.56,23.22,23.22,0,0,0-3.26-6.93,37.27,37.27,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.6-2.23-1.05-3-1.36H797.1v6.87c.28.58.61,1.25,1,2a41.4,41.4,0,0,0,6.8,9.29,36.46,36.46,0,0,0,7.1,5.63,22.92,22.92,0,0,0,7,3,20.84,20.84,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M824.59,261.12a20.84,20.84,0,0,0-5.58.51,22.68,22.68,0,0,0-7,3.05,36.25,36.25,0,0,0-7.1,5.62,41.56,41.56,0,0,0-6.8,9.3c-.39.73-.72,1.4-1,2v6.87h5.16c.75-.3,1.78-.75,3-1.35a41.26,41.26,0,0,0,9.5-6.52,36.6,36.6,0,0,0,5.84-6.92,23.15,23.15,0,0,0,3.26-6.92,20.61,20.61,0,0,0,.67-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M844,270.3a36.62,36.62,0,0,0-7.09-5.62,22.85,22.85,0,0,0-7-3.05,20.84,20.84,0,0,0-5.58-.51l.08.06a20.69,20.69,0,0,0,.68,5.56,23.16,23.16,0,0,0,3.25,6.92,37,37,0,0,0,5.84,6.92,41.77,41.77,0,0,0,9.5,6.52c1.2.6,2.24,1,3,1.35h5.15v-6.88c-.28-.6-.58-1.19-1-2A41.56,41.56,0,0,0,844,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="756.1"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M851.79,291.11h-5.15c-.75.31-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37,37,0,0,0-5.84,6.92,23.16,23.16,0,0,0-3.25,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.3,41.3,0,0,0,6.8-9.3c.39-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M824.51,318.39a20.64,20.64,0,0,0-.67-5.57,23.15,23.15,0,0,0-3.26-6.92,36.6,36.6,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.61-2.23-1.05-3-1.36H797.1V298c.28.58.61,1.25,1,2a41.3,41.3,0,0,0,6.8,9.3,36.46,36.46,0,0,0,7.1,5.63,22.92,22.92,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M824.59,318.47A20.84,20.84,0,0,0,819,319a22.92,22.92,0,0,0-7,3,36.46,36.46,0,0,0-7.1,5.63,41.56,41.56,0,0,0-6.8,9.3c-.39.73-.72,1.4-1,2v6.87h5.16c.75-.3,1.78-.75,3-1.36a41,41,0,0,0,9.5-6.51,36.6,36.6,0,0,0,5.84-6.92,23.15,23.15,0,0,0,3.26-6.92,20.64,20.64,0,0,0,.67-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M844,327.65A36.84,36.84,0,0,0,836.9,322a23.1,23.1,0,0,0-7-3,20.84,20.84,0,0,0-5.58-.51l.08,0a20.71,20.71,0,0,0,.68,5.57,23.16,23.16,0,0,0,3.25,6.92,37,37,0,0,0,5.84,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1.06,3,1.36h5.15v-6.88c-.28-.6-.58-1.19-1-2A41.56,41.56,0,0,0,844,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="756.1"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M851.79,348.46h-5.15c-.75.3-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37,37,0,0,0-5.84,6.92,23.16,23.16,0,0,0-3.25,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.56,41.56,0,0,0,6.8-9.3c.39-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M824.51,375.74a20.64,20.64,0,0,0-.67-5.57,23.15,23.15,0,0,0-3.26-6.92,36.6,36.6,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.61-2.23-1.06-3-1.36H797.1v6.87c.28.58.61,1.25,1,2a41.56,41.56,0,0,0,6.8,9.3,36.46,36.46,0,0,0,7.1,5.63,22.92,22.92,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M824.59,375.82a20.43,20.43,0,0,0-5.58.51,22.92,22.92,0,0,0-7,3,36.46,36.46,0,0,0-7.1,5.63,41.3,41.3,0,0,0-6.8,9.3c-.39.73-.72,1.4-1,2v6.87h5.16c.75-.31,1.78-.75,3-1.36a41,41,0,0,0,9.5-6.51,36.6,36.6,0,0,0,5.84-6.92,23.15,23.15,0,0,0,3.26-6.92,20.64,20.64,0,0,0,.67-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M844,385a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.43,20.43,0,0,0-5.58-.51l.08.05a20.71,20.71,0,0,0,.68,5.57,23.16,23.16,0,0,0,3.25,6.92,37,37,0,0,0,5.84,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1,3,1.36h5.15v-6.89c-.28-.6-.58-1.19-1-2A41.3,41.3,0,0,0,844,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
          <g>
            <g>
              <rect
                x="698.41"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M794.1,233.76h-5.16c-.75.31-1.78.76-3,1.36a41,41,0,0,0-9.5,6.51,37.27,37.27,0,0,0-5.84,6.92,23.22,23.22,0,0,0-3.26,6.93,20.61,20.61,0,0,0-.67,5.56l-.08.06a20.84,20.84,0,0,0,5.58-.51,22.92,22.92,0,0,0,7-3,36.46,36.46,0,0,0,7.1-5.63,41.4,41.4,0,0,0,6.8-9.29c.39-.74.72-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M766.82,261a20.69,20.69,0,0,0-.68-5.56,23.22,23.22,0,0,0-3.25-6.93,37.71,37.71,0,0,0-5.84-6.92,41.27,41.27,0,0,0-9.5-6.51c-1.2-.6-2.24-1.05-3-1.36h-5.16v6.87c.27.58.61,1.25,1,2a41.4,41.4,0,0,0,6.8,9.29,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.84,20.84,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M766.9,261.12a20.84,20.84,0,0,0-5.58.51,22.85,22.85,0,0,0-7,3.05,36.62,36.62,0,0,0-7.09,5.62,41.56,41.56,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.16c.74-.3,1.78-.75,3-1.35a41.51,41.51,0,0,0,9.5-6.52,37,37,0,0,0,5.84-6.92,23.16,23.16,0,0,0,3.25-6.92,20.69,20.69,0,0,0,.68-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M786.3,270.3a36.25,36.25,0,0,0-7.1-5.62,22.68,22.68,0,0,0-7-3.05,20.84,20.84,0,0,0-5.58-.51l.08.06a20.61,20.61,0,0,0,.67,5.56,23.15,23.15,0,0,0,3.26,6.92,36.6,36.6,0,0,0,5.84,6.92,41.26,41.26,0,0,0,9.5,6.52c1.2.6,2.23,1,3,1.35h5.16v-6.88c-.28-.6-.58-1.19-1-2A41.56,41.56,0,0,0,786.3,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="698.41"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M794.1,291.11h-5.16c-.75.31-1.78.75-3,1.36a41,41,0,0,0-9.5,6.51,36.6,36.6,0,0,0-5.84,6.92,23.15,23.15,0,0,0-3.26,6.92,20.64,20.64,0,0,0-.67,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,22.92,22.92,0,0,0,7-3,36.46,36.46,0,0,0,7.1-5.63,41.3,41.3,0,0,0,6.8-9.3c.39-.73.72-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M766.82,318.39a20.71,20.71,0,0,0-.68-5.57,23.16,23.16,0,0,0-3.25-6.92,37,37,0,0,0-5.84-6.92,41.27,41.27,0,0,0-9.5-6.51c-1.2-.61-2.24-1.05-3-1.36h-5.16V298c.27.58.61,1.25,1,2a41.3,41.3,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M766.9,318.47a20.84,20.84,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41.56,41.56,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.16c.74-.3,1.78-.75,3-1.36a41.27,41.27,0,0,0,9.5-6.51,37,37,0,0,0,5.84-6.92,23.16,23.16,0,0,0,3.25-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M786.3,327.65a36.46,36.46,0,0,0-7.1-5.63,22.92,22.92,0,0,0-7-3,20.84,20.84,0,0,0-5.58-.51l.08,0a20.64,20.64,0,0,0,.67,5.57,23.15,23.15,0,0,0,3.26,6.92,36.6,36.6,0,0,0,5.84,6.92,41,41,0,0,0,9.5,6.51c1.2.61,2.23,1.06,3,1.36h5.16v-6.88c-.28-.6-.58-1.19-1-2A41.56,41.56,0,0,0,786.3,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="698.41"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M794.1,348.46h-5.16c-.75.3-1.78.75-3,1.36a41,41,0,0,0-9.5,6.51,36.6,36.6,0,0,0-5.84,6.92,23.15,23.15,0,0,0-3.26,6.92,20.64,20.64,0,0,0-.67,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,22.92,22.92,0,0,0,7-3,36.46,36.46,0,0,0,7.1-5.63,41.56,41.56,0,0,0,6.8-9.3c.39-.73.72-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M766.82,375.74a20.71,20.71,0,0,0-.68-5.57,23.16,23.16,0,0,0-3.25-6.92,37,37,0,0,0-5.84-6.92,41.27,41.27,0,0,0-9.5-6.51c-1.2-.61-2.24-1.06-3-1.36h-5.16v6.87c.27.58.61,1.25,1,2a41.56,41.56,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M766.9,375.82a20.43,20.43,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41.3,41.3,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.16c.74-.31,1.78-.75,3-1.36a41.27,41.27,0,0,0,9.5-6.51,37,37,0,0,0,5.84-6.92,23.16,23.16,0,0,0,3.25-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M786.3,385a36.46,36.46,0,0,0-7.1-5.63,22.92,22.92,0,0,0-7-3,20.43,20.43,0,0,0-5.58-.51l.08.05a20.64,20.64,0,0,0,.67,5.57,23.15,23.15,0,0,0,3.26,6.92,36.6,36.6,0,0,0,5.84,6.92,41,41,0,0,0,9.5,6.51c1.2.61,2.23,1,3,1.36h5.16v-6.89c-.28-.6-.58-1.19-1-2A41.3,41.3,0,0,0,786.3,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <rect
                x="640.72"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M736.41,233.76h-5.16c-.75.31-1.78.76-3,1.36a41,41,0,0,0-9.5,6.51,37.27,37.27,0,0,0-5.84,6.92,23.22,23.22,0,0,0-3.26,6.93A21.11,21.11,0,0,0,709,261l-.08.06a20.88,20.88,0,0,0,5.59-.51,23,23,0,0,0,7-3,36.77,36.77,0,0,0,7.1-5.63,41.49,41.49,0,0,0,6.79-9.29c.4-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M709.13,261a20.69,20.69,0,0,0-.68-5.56,23,23,0,0,0-3.26-6.93,37,37,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.6-2.24-1.05-3-1.36h-5.15v6.87c.27.58.61,1.25,1,2a41.07,41.07,0,0,0,6.8,9.29,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.84,20.84,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M709.21,261.12a20.84,20.84,0,0,0-5.58.51,22.85,22.85,0,0,0-7,3.05,36.62,36.62,0,0,0-7.09,5.62,41.24,41.24,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.15c.75-.3,1.79-.75,3-1.35a41.77,41.77,0,0,0,9.5-6.52,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.69,20.69,0,0,0,.68-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M728.61,270.3a36.56,36.56,0,0,0-7.1-5.62,22.78,22.78,0,0,0-7-3.05,20.88,20.88,0,0,0-5.59-.51l.08.06a21.11,21.11,0,0,0,.68,5.56,23.15,23.15,0,0,0,3.26,6.92,36.6,36.6,0,0,0,5.84,6.92,41.26,41.26,0,0,0,9.5,6.52c1.2.6,2.23,1,3,1.35h5.16v-6.88c-.28-.6-.59-1.19-1-2A41.65,41.65,0,0,0,728.61,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="640.72"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M736.41,291.11h-5.16c-.75.31-1.78.75-3,1.36a41,41,0,0,0-9.5,6.51,36.6,36.6,0,0,0-5.84,6.92,23.15,23.15,0,0,0-3.26,6.92,21.13,21.13,0,0,0-.68,5.57l-.08.05a20.47,20.47,0,0,0,5.59-.51,23,23,0,0,0,7-3,36.77,36.77,0,0,0,7.1-5.63,41.39,41.39,0,0,0,6.79-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M709.13,318.39a20.71,20.71,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92,36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.05-3-1.36h-5.15V298c.27.58.61,1.25,1,2a41,41,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M709.21,318.47a20.84,20.84,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41.24,41.24,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.15c.75-.3,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M728.61,327.65a36.77,36.77,0,0,0-7.1-5.63,23,23,0,0,0-7-3,20.88,20.88,0,0,0-5.59-.51l.08,0a21.13,21.13,0,0,0,.68,5.57,23.15,23.15,0,0,0,3.26,6.92,36.6,36.6,0,0,0,5.84,6.92,41,41,0,0,0,9.5,6.51c1.2.61,2.23,1.06,3,1.36h5.16v-6.88c-.28-.6-.59-1.19-1-2A41.65,41.65,0,0,0,728.61,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="640.72"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M736.41,348.46h-5.16c-.75.3-1.78.75-3,1.36a41,41,0,0,0-9.5,6.51,36.6,36.6,0,0,0-5.84,6.92,23.15,23.15,0,0,0-3.26,6.92,21.13,21.13,0,0,0-.68,5.57l-.08.05a20.47,20.47,0,0,0,5.59-.51,23,23,0,0,0,7-3,36.77,36.77,0,0,0,7.1-5.63,41.65,41.65,0,0,0,6.79-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M709.13,375.74a20.71,20.71,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92,36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.06-3-1.36h-5.15v6.87c.27.58.61,1.25,1,2a41.24,41.24,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M709.21,375.82a20.43,20.43,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41,41,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.15c.75-.31,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M728.61,385a36.77,36.77,0,0,0-7.1-5.63,23,23,0,0,0-7-3,20.47,20.47,0,0,0-5.59-.51l.08.05a21.13,21.13,0,0,0,.68,5.57,23.15,23.15,0,0,0,3.26,6.92,36.6,36.6,0,0,0,5.84,6.92,41,41,0,0,0,9.5,6.51c1.2.61,2.23,1,3,1.36h5.16v-6.89c-.28-.6-.59-1.19-1-2A41.39,41.39,0,0,0,728.61,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
          <g>
            <g>
              <rect
                x="583.02"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M678.72,233.76h-5.16c-.75.31-1.79.76-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37,37,0,0,0-5.83,6.92,23,23,0,0,0-3.26,6.93,20.69,20.69,0,0,0-.68,5.56l-.08.06a20.81,20.81,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.07,41.07,0,0,0,6.8-9.29c.4-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M651.44,261a20.69,20.69,0,0,0-.68-5.56,23,23,0,0,0-3.26-6.93,37,37,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.6-2.24-1.05-3-1.36H624v6.87c.28.58.61,1.25,1,2a41.07,41.07,0,0,0,6.8,9.29,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.81,20.81,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M651.52,261.12a20.81,20.81,0,0,0-5.58.51,22.85,22.85,0,0,0-7,3.05,36.62,36.62,0,0,0-7.09,5.62,41.24,41.24,0,0,0-6.8,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.79-.75,3-1.35a41.77,41.77,0,0,0,9.5-6.52,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.69,20.69,0,0,0,.68-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M670.91,270.3a36.62,36.62,0,0,0-7.09-5.62,22.85,22.85,0,0,0-7-3.05,20.81,20.81,0,0,0-5.58-.51l.08.06a20.69,20.69,0,0,0,.68,5.56,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.77,41.77,0,0,0,9.5,6.52c1.2.6,2.24,1,3,1.35h5.16v-6.88c-.29-.6-.59-1.19-1-2A41.24,41.24,0,0,0,670.91,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="583.02"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M678.72,291.11h-5.16c-.75.31-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,36.39,36.39,0,0,0-5.83,6.92,22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.4,20.4,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41,41,0,0,0,6.8-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M651.44,318.39a20.71,20.71,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92,36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.05-3-1.36H624V298c.28.58.61,1.25,1,2a41,41,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.4,20.4,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M651.52,318.47a20.81,20.81,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63A41.24,41.24,0,0,0,625,337c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51A36.39,36.39,0,0,0,647.5,331a22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M670.91,327.65a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.81,20.81,0,0,0-5.58-.51l.08,0a20.71,20.71,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1.06,3,1.36h5.16v-6.88c-.29-.6-.59-1.19-1-2A41.24,41.24,0,0,0,670.91,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="583.02"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M678.72,348.46h-5.16c-.75.3-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,36.39,36.39,0,0,0-5.83,6.92,22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.4,20.4,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.24,41.24,0,0,0,6.8-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M651.44,375.74a20.71,20.71,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92,36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.06-3-1.36H624v6.87c.28.58.61,1.25,1,2a41.24,41.24,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.4,20.4,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M651.52,375.82a20.4,20.4,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41,41,0,0,0-6.8,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.31,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M670.91,385a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.4,20.4,0,0,0-5.58-.51l.08.05a20.71,20.71,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1,3,1.36h5.16v-6.89c-.29-.6-.59-1.19-1-2A41,41,0,0,0,670.91,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <rect
                x="525.33"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M621,233.76h-5.15c-.75.31-1.79.76-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37,37,0,0,0-5.83,6.92,23,23,0,0,0-3.26,6.93,20.69,20.69,0,0,0-.68,5.56l-.08.06a20.84,20.84,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.07,41.07,0,0,0,6.8-9.29c.4-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M593.75,261a21.11,21.11,0,0,0-.68-5.56,23,23,0,0,0-3.26-6.93,36.84,36.84,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.6-2.23-1.05-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.49,41.49,0,0,0,6.79,9.29,36.77,36.77,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.88,20.88,0,0,0,5.59.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M593.83,261.12a20.88,20.88,0,0,0-5.59.51,22.78,22.78,0,0,0-7,3.05,36.56,36.56,0,0,0-7.1,5.62,41.65,41.65,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.78-.75,3-1.35a41.26,41.26,0,0,0,9.5-6.52,36.19,36.19,0,0,0,5.84-6.92,22.91,22.91,0,0,0,3.26-6.92,21.11,21.11,0,0,0,.68-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M613.22,270.3a36.62,36.62,0,0,0-7.09-5.62,22.85,22.85,0,0,0-7-3.05,20.84,20.84,0,0,0-5.58-.51l.08.06a20.69,20.69,0,0,0,.68,5.56,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.77,41.77,0,0,0,9.5,6.52c1.2.6,2.24,1,3,1.35H621v-6.88c-.28-.6-.58-1.19-1-2A41.24,41.24,0,0,0,613.22,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="525.33"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M621,291.11h-5.15c-.75.31-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,36.39,36.39,0,0,0-5.83,6.92,22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63A41,41,0,0,0,620,300c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M593.75,318.39a21.13,21.13,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92A36.19,36.19,0,0,0,584,299a41,41,0,0,0-9.5-6.51c-1.2-.61-2.23-1.05-3-1.36h-5.16V298c.28.58.61,1.25,1,2a41.39,41.39,0,0,0,6.79,9.3,36.77,36.77,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.47,20.47,0,0,0,5.59.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M593.83,318.47a20.88,20.88,0,0,0-5.59.51,23,23,0,0,0-7,3,36.77,36.77,0,0,0-7.1,5.63,41.65,41.65,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.78-.75,3-1.36a41,41,0,0,0,9.5-6.51,36.19,36.19,0,0,0,5.84-6.92,22.91,22.91,0,0,0,3.26-6.92,21.13,21.13,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M613.22,327.65a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.84,20.84,0,0,0-5.58-.51l.08,0a20.71,20.71,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1.06,3,1.36H621v-6.88c-.28-.6-.58-1.19-1-2A41.24,41.24,0,0,0,613.22,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="525.33"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M621,348.46h-5.15c-.75.3-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,36.39,36.39,0,0,0-5.83,6.92,22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.24,41.24,0,0,0,6.8-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M593.75,375.74a21.13,21.13,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92,36.19,36.19,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.61-2.23-1.06-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.65,41.65,0,0,0,6.79,9.3,36.77,36.77,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.47,20.47,0,0,0,5.59.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M593.83,375.82a20.47,20.47,0,0,0-5.59.51,23,23,0,0,0-7,3,36.77,36.77,0,0,0-7.1,5.63,41.39,41.39,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.31,1.78-.75,3-1.36a41,41,0,0,0,9.5-6.51,36.19,36.19,0,0,0,5.84-6.92,22.91,22.91,0,0,0,3.26-6.92,21.13,21.13,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M613.22,385a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.43,20.43,0,0,0-5.58-.51l.08.05a20.71,20.71,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1,3,1.36H621v-6.89c-.28-.6-.58-1.19-1-2A41,41,0,0,0,613.22,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
          <g>
            <g>
              <rect
                x="467.64"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M563.33,233.76h-5.15c-.75.31-1.79.76-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37.71,37.71,0,0,0-5.84,6.92,23.22,23.22,0,0,0-3.25,6.93,20.69,20.69,0,0,0-.68,5.56l-.08.06a20.84,20.84,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.4,41.4,0,0,0,6.8-9.29c.39-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M536.05,261a20.61,20.61,0,0,0-.67-5.56,23.22,23.22,0,0,0-3.26-6.93,37.27,37.27,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.6-2.23-1.05-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.16,41.16,0,0,0,6.79,9.29,36.46,36.46,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.84,20.84,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M536.13,261.12a20.84,20.84,0,0,0-5.58.51,22.78,22.78,0,0,0-7,3.05,36.25,36.25,0,0,0-7.1,5.62,41.32,41.32,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.78-.75,3-1.35a41.26,41.26,0,0,0,9.5-6.52,36.6,36.6,0,0,0,5.84-6.92,23.15,23.15,0,0,0,3.26-6.92,20.61,20.61,0,0,0,.67-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M555.53,270.3a36.62,36.62,0,0,0-7.09-5.62,22.85,22.85,0,0,0-7-3.05,20.84,20.84,0,0,0-5.58-.51l.08.06a20.69,20.69,0,0,0,.68,5.56,23.16,23.16,0,0,0,3.25,6.92,37,37,0,0,0,5.84,6.92,41.77,41.77,0,0,0,9.5,6.52c1.2.6,2.24,1,3,1.35h5.15v-6.88c-.28-.6-.58-1.19-1-2A41.56,41.56,0,0,0,555.53,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="467.64"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M563.33,291.11h-5.15c-.75.31-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37,37,0,0,0-5.84,6.92,23.16,23.16,0,0,0-3.25,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.3,41.3,0,0,0,6.8-9.3c.39-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M536.05,318.39a20.64,20.64,0,0,0-.67-5.57,23.15,23.15,0,0,0-3.26-6.92,36.6,36.6,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.61-2.23-1.05-3-1.36h-5.16V298c.28.58.61,1.25,1,2a41.06,41.06,0,0,0,6.79,9.3,36.46,36.46,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M536.13,318.47a20.84,20.84,0,0,0-5.58.51,23,23,0,0,0-7,3,36.46,36.46,0,0,0-7.1,5.63,41.32,41.32,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.78-.75,3-1.36a41,41,0,0,0,9.5-6.51,36.6,36.6,0,0,0,5.84-6.92,23.15,23.15,0,0,0,3.26-6.92,20.64,20.64,0,0,0,.67-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M555.53,327.65a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.84,20.84,0,0,0-5.58-.51l.08,0a20.71,20.71,0,0,0,.68,5.57,23.16,23.16,0,0,0,3.25,6.92,37,37,0,0,0,5.84,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1.06,3,1.36h5.15v-6.88c-.28-.6-.58-1.19-1-2A41.56,41.56,0,0,0,555.53,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="467.64"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M563.33,348.46h-5.15c-.75.3-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37,37,0,0,0-5.84,6.92,23.16,23.16,0,0,0-3.25,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.56,41.56,0,0,0,6.8-9.3c.39-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M536.05,375.74a20.64,20.64,0,0,0-.67-5.57,23.15,23.15,0,0,0-3.26-6.92,36.6,36.6,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.61-2.23-1.06-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.32,41.32,0,0,0,6.79,9.3,36.46,36.46,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M536.13,375.82a20.43,20.43,0,0,0-5.58.51,23,23,0,0,0-7,3,36.46,36.46,0,0,0-7.1,5.63,41.06,41.06,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.31,1.78-.75,3-1.36a41,41,0,0,0,9.5-6.51,36.6,36.6,0,0,0,5.84-6.92,23.15,23.15,0,0,0,3.26-6.92,20.64,20.64,0,0,0,.67-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M555.53,385a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.43,20.43,0,0,0-5.58-.51l.08.05a20.71,20.71,0,0,0,.68,5.57,23.16,23.16,0,0,0,3.25,6.92,37,37,0,0,0,5.84,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1,3,1.36h5.15v-6.89c-.28-.6-.58-1.19-1-2A41.3,41.3,0,0,0,555.53,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <rect
                x="409.95"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M505.64,233.76h-5.16c-.75.31-1.78.76-3,1.36a41.27,41.27,0,0,0-9.5,6.51,37.27,37.27,0,0,0-5.84,6.92,23,23,0,0,0-3.25,6.93,20.29,20.29,0,0,0-.68,5.56l-.08.06a20.84,20.84,0,0,0,5.58-.51,22.92,22.92,0,0,0,7-3,36.46,36.46,0,0,0,7.1-5.63,41.4,41.4,0,0,0,6.8-9.29c.39-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M478.36,261a20.69,20.69,0,0,0-.68-5.56,23.22,23.22,0,0,0-3.25-6.93,37.71,37.71,0,0,0-5.84-6.92,41.27,41.27,0,0,0-9.5-6.51c-1.2-.6-2.24-1.05-3-1.36H451v6.87c.27.58.61,1.25,1,2a41.4,41.4,0,0,0,6.8,9.29,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.84,20.84,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M478.44,261.12a20.84,20.84,0,0,0-5.58.51,22.85,22.85,0,0,0-7,3.05,36.62,36.62,0,0,0-7.09,5.62,41.56,41.56,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.16c.74-.3,1.78-.75,3-1.35a41.51,41.51,0,0,0,9.5-6.52,37,37,0,0,0,5.84-6.92,23.16,23.16,0,0,0,3.25-6.92,20.69,20.69,0,0,0,.68-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M497.84,270.3a36.25,36.25,0,0,0-7.1-5.62,22.68,22.68,0,0,0-7-3.05,20.84,20.84,0,0,0-5.58-.51l.08.06a20.29,20.29,0,0,0,.68,5.56,22.91,22.91,0,0,0,3.25,6.92,36.6,36.6,0,0,0,5.84,6.92,41.51,41.51,0,0,0,9.5,6.52c1.2.6,2.23,1,3,1.35h5.16v-6.88c-.28-.6-.58-1.19-1-2A41.56,41.56,0,0,0,497.84,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="409.95"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M505.64,291.11h-5.16c-.75.31-1.78.75-3,1.36A41.27,41.27,0,0,0,488,299a36.6,36.6,0,0,0-5.84,6.92,22.91,22.91,0,0,0-3.25,6.92,20.31,20.31,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,22.92,22.92,0,0,0,7-3,36.46,36.46,0,0,0,7.1-5.63,41.3,41.3,0,0,0,6.8-9.3c.39-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M478.36,318.39a20.71,20.71,0,0,0-.68-5.57,23.16,23.16,0,0,0-3.25-6.92,37,37,0,0,0-5.84-6.92,41.27,41.27,0,0,0-9.5-6.51c-1.2-.61-2.24-1.05-3-1.36H451V298c.27.58.61,1.25,1,2a41.3,41.3,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M478.44,318.47a20.84,20.84,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63A41.56,41.56,0,0,0,452,337c-.39.73-.73,1.4-1,2v6.87h5.16c.74-.3,1.78-.75,3-1.36a41.27,41.27,0,0,0,9.5-6.51,37,37,0,0,0,5.84-6.92,23.16,23.16,0,0,0,3.25-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M497.84,327.65a36.46,36.46,0,0,0-7.1-5.63,22.92,22.92,0,0,0-7-3,20.84,20.84,0,0,0-5.58-.51l.08,0a20.31,20.31,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.25,6.92,36.6,36.6,0,0,0,5.84,6.92,41.27,41.27,0,0,0,9.5,6.51c1.2.61,2.23,1.06,3,1.36h5.16v-6.88c-.28-.6-.58-1.19-1-2A41.56,41.56,0,0,0,497.84,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="409.95"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M505.64,348.46h-5.16c-.75.3-1.78.75-3,1.36a41.27,41.27,0,0,0-9.5,6.51,36.6,36.6,0,0,0-5.84,6.92,22.91,22.91,0,0,0-3.25,6.92,20.31,20.31,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,22.92,22.92,0,0,0,7-3,36.46,36.46,0,0,0,7.1-5.63,41.56,41.56,0,0,0,6.8-9.3c.39-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M478.36,375.74a20.71,20.71,0,0,0-.68-5.57,23.16,23.16,0,0,0-3.25-6.92,37,37,0,0,0-5.84-6.92,41.27,41.27,0,0,0-9.5-6.51c-1.2-.61-2.24-1.06-3-1.36H451v6.87c.27.58.61,1.25,1,2a41.56,41.56,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M478.44,375.82a20.43,20.43,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41.3,41.3,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.16c.74-.31,1.78-.75,3-1.36a41.27,41.27,0,0,0,9.5-6.51,37,37,0,0,0,5.84-6.92,23.16,23.16,0,0,0,3.25-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M497.84,385a36.46,36.46,0,0,0-7.1-5.63,22.92,22.92,0,0,0-7-3,20.43,20.43,0,0,0-5.58-.51l.08.05a20.31,20.31,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.25,6.92,36.6,36.6,0,0,0,5.84,6.92,41.27,41.27,0,0,0,9.5,6.51c1.2.61,2.23,1,3,1.36h5.16v-6.89c-.28-.6-.58-1.19-1-2A41.3,41.3,0,0,0,497.84,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
          <g>
            <g>
              <rect
                x="352.26"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M448,233.76h-5.16c-.75.31-1.78.76-3,1.36a41,41,0,0,0-9.5,6.51,37.27,37.27,0,0,0-5.84,6.92,23.22,23.22,0,0,0-3.26,6.93,21.11,21.11,0,0,0-.68,5.56l-.08.06a20.88,20.88,0,0,0,5.59-.51,23,23,0,0,0,7-3,36.77,36.77,0,0,0,7.1-5.63,41.49,41.49,0,0,0,6.79-9.29c.4-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M420.67,261a20.69,20.69,0,0,0-.68-5.56,23,23,0,0,0-3.26-6.93,37,37,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.6-2.24-1.05-3-1.36h-5.15v6.87c.27.58.61,1.25,1,2a41.07,41.07,0,0,0,6.8,9.29,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.84,20.84,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M420.75,261.12a20.84,20.84,0,0,0-5.58.51,22.85,22.85,0,0,0-7,3.05,36.62,36.62,0,0,0-7.09,5.62,41.24,41.24,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.15c.75-.3,1.79-.75,3-1.35a41.77,41.77,0,0,0,9.5-6.52,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.69,20.69,0,0,0,.68-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M440.15,270.3a36.56,36.56,0,0,0-7.1-5.62,22.78,22.78,0,0,0-7-3.05,20.88,20.88,0,0,0-5.59-.51l.08.06a21.11,21.11,0,0,0,.68,5.56,23.15,23.15,0,0,0,3.26,6.92,36.6,36.6,0,0,0,5.84,6.92,41.26,41.26,0,0,0,9.5,6.52c1.2.6,2.23,1,3,1.35H448v-6.88c-.28-.6-.59-1.19-1-2A41.65,41.65,0,0,0,440.15,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="352.26"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M448,291.11h-5.16c-.75.31-1.78.75-3,1.36a41,41,0,0,0-9.5,6.51,36.6,36.6,0,0,0-5.84,6.92,23.15,23.15,0,0,0-3.26,6.92,21.13,21.13,0,0,0-.68,5.57l-.08.05a20.47,20.47,0,0,0,5.59-.51,23,23,0,0,0,7-3,36.77,36.77,0,0,0,7.1-5.63,41.39,41.39,0,0,0,6.79-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M420.67,318.39a20.71,20.71,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92A36.39,36.39,0,0,0,410.9,299a41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.05-3-1.36h-5.15V298c.27.58.61,1.25,1,2a41,41,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M420.75,318.47a20.84,20.84,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41.24,41.24,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.15c.75-.3,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M440.15,327.65a36.77,36.77,0,0,0-7.1-5.63,23,23,0,0,0-7-3,20.88,20.88,0,0,0-5.59-.51l.08,0a21.13,21.13,0,0,0,.68,5.57,23.15,23.15,0,0,0,3.26,6.92,36.6,36.6,0,0,0,5.84,6.92,41,41,0,0,0,9.5,6.51c1.2.61,2.23,1.06,3,1.36H448v-6.88c-.28-.6-.59-1.19-1-2A41.65,41.65,0,0,0,440.15,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="352.26"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M448,348.46h-5.16c-.75.3-1.78.75-3,1.36a41,41,0,0,0-9.5,6.51,36.6,36.6,0,0,0-5.84,6.92,23.15,23.15,0,0,0-3.26,6.92,21.13,21.13,0,0,0-.68,5.57l-.08.05a20.47,20.47,0,0,0,5.59-.51,23,23,0,0,0,7-3,36.77,36.77,0,0,0,7.1-5.63,41.65,41.65,0,0,0,6.79-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M420.67,375.74a20.71,20.71,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92,36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.06-3-1.36h-5.15v6.87c.27.58.61,1.25,1,2a41.24,41.24,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M420.75,375.82a20.43,20.43,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41,41,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.15c.75-.31,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M440.15,385a36.77,36.77,0,0,0-7.1-5.63,23,23,0,0,0-7-3,20.47,20.47,0,0,0-5.59-.51l.08.05a21.13,21.13,0,0,0,.68,5.57,23.15,23.15,0,0,0,3.26,6.92,36.6,36.6,0,0,0,5.84,6.92,41,41,0,0,0,9.5,6.51c1.2.61,2.23,1,3,1.36H448v-6.89c-.28-.6-.59-1.19-1-2A41.39,41.39,0,0,0,440.15,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <rect
                x="294.56"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M390.26,233.76H385.1c-.75.31-1.79.76-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37,37,0,0,0-5.83,6.92,23,23,0,0,0-3.26,6.93,20.69,20.69,0,0,0-.68,5.56l-.08.06a20.81,20.81,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.07,41.07,0,0,0,6.8-9.29c.4-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M363,261a20.69,20.69,0,0,0-.68-5.56,23,23,0,0,0-3.26-6.93,37,37,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.6-2.24-1.05-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.07,41.07,0,0,0,6.8,9.29,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.81,20.81,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M363.06,261.12a20.81,20.81,0,0,0-5.58.51,22.85,22.85,0,0,0-7,3.05,36.62,36.62,0,0,0-7.09,5.62,41.24,41.24,0,0,0-6.8,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.79-.75,3-1.35a41.77,41.77,0,0,0,9.5-6.52,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.69,20.69,0,0,0,.68-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M382.45,270.3a36.62,36.62,0,0,0-7.09-5.62,22.85,22.85,0,0,0-7-3.05,20.81,20.81,0,0,0-5.58-.51l.08.06a20.69,20.69,0,0,0,.68,5.56,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.77,41.77,0,0,0,9.5,6.52c1.2.6,2.24,1,3,1.35h5.16v-6.88c-.29-.6-.59-1.19-1-2A41.24,41.24,0,0,0,382.45,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="294.56"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M390.26,291.11H385.1c-.75.31-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,36.39,36.39,0,0,0-5.83,6.92,22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.4,20.4,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41,41,0,0,0,6.8-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M363,318.39a20.71,20.71,0,0,0-.68-5.57A22.91,22.91,0,0,0,359,305.9a36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.05-3-1.36h-5.16V298c.28.58.61,1.25,1,2a41,41,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.4,20.4,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M363.06,318.47a20.81,20.81,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41.24,41.24,0,0,0-6.8,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51A36.39,36.39,0,0,0,359,331a22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M382.45,327.65a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.81,20.81,0,0,0-5.58-.51l.08,0a20.71,20.71,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1.06,3,1.36h5.16v-6.88c-.29-.6-.59-1.19-1-2A41.24,41.24,0,0,0,382.45,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="294.56"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M390.26,348.46H385.1c-.75.3-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,36.39,36.39,0,0,0-5.83,6.92,22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.4,20.4,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.24,41.24,0,0,0,6.8-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M363,375.74a20.71,20.71,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92,36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.06-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.24,41.24,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.4,20.4,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M363.06,375.82a20.4,20.4,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41,41,0,0,0-6.8,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.31,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M382.45,385a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.4,20.4,0,0,0-5.58-.51l.08.05a20.71,20.71,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1,3,1.36h5.16v-6.89c-.29-.6-.59-1.19-1-2A41,41,0,0,0,382.45,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
          <g>
            <g>
              <rect
                x="236.87"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M332.56,233.76h-5.15c-.75.31-1.79.76-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37,37,0,0,0-5.83,6.92,23,23,0,0,0-3.26,6.93,20.69,20.69,0,0,0-.68,5.56l-.08.06a20.84,20.84,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.07,41.07,0,0,0,6.8-9.29c.4-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M305.29,261a21.11,21.11,0,0,0-.68-5.56,23,23,0,0,0-3.26-6.93,37,37,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.21-.6-2.24-1.05-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.49,41.49,0,0,0,6.79,9.29,36.77,36.77,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.88,20.88,0,0,0,5.59.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M305.37,261.12a20.88,20.88,0,0,0-5.59.51,22.78,22.78,0,0,0-7,3.05,36.56,36.56,0,0,0-7.1,5.62,41.65,41.65,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87H283c.75-.3,1.78-.75,3-1.35a41.77,41.77,0,0,0,9.5-6.52,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,21.11,21.11,0,0,0,.68-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M324.76,270.3a36.62,36.62,0,0,0-7.09-5.62,22.85,22.85,0,0,0-7-3.05,20.84,20.84,0,0,0-5.58-.51l.08.06a20.69,20.69,0,0,0,.68,5.56,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.77,41.77,0,0,0,9.5,6.52c1.2.6,2.24,1,3,1.35h5.15v-6.88c-.28-.6-.58-1.19-1-2A41.24,41.24,0,0,0,324.76,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="236.87"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M332.56,291.11h-5.15c-.75.31-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,36.39,36.39,0,0,0-5.83,6.92,22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41,41,0,0,0,6.8-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M305.29,318.39a21.13,21.13,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92,36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.21-.61-2.24-1.05-3-1.36h-5.16V298c.28.58.61,1.25,1,2a41.39,41.39,0,0,0,6.79,9.3,36.77,36.77,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.47,20.47,0,0,0,5.59.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M305.37,318.47a20.88,20.88,0,0,0-5.59.51,23,23,0,0,0-7,3,36.77,36.77,0,0,0-7.1,5.63,41.65,41.65,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87H283c.75-.3,1.78-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,21.13,21.13,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M324.76,327.65a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.84,20.84,0,0,0-5.58-.51l.08,0a20.71,20.71,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1.06,3,1.36h5.15v-6.88c-.28-.6-.58-1.19-1-2A41.24,41.24,0,0,0,324.76,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="236.87"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M332.56,348.46h-5.15c-.75.3-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,36.39,36.39,0,0,0-5.83,6.92,22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.24,41.24,0,0,0,6.8-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M305.29,375.74a21.13,21.13,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92,36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.21-.61-2.24-1.06-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.65,41.65,0,0,0,6.79,9.3,36.77,36.77,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.47,20.47,0,0,0,5.59.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M305.37,375.82a20.47,20.47,0,0,0-5.59.51,23,23,0,0,0-7,3,36.77,36.77,0,0,0-7.1,5.63,41.39,41.39,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87H283c.75-.31,1.78-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,21.13,21.13,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M324.76,385a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.43,20.43,0,0,0-5.58-.51l.08.05a20.71,20.71,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1,3,1.36h5.15v-6.89c-.28-.6-.58-1.19-1-2A41,41,0,0,0,324.76,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <rect
                x="179.18"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M274.87,233.76h-5.15c-.75.31-1.79.76-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37.71,37.71,0,0,0-5.84,6.92,23.22,23.22,0,0,0-3.25,6.93,20.69,20.69,0,0,0-.68,5.56l-.08.06a20.84,20.84,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.4,41.4,0,0,0,6.8-9.29c.39-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M247.59,261a20.61,20.61,0,0,0-.67-5.56,23.22,23.22,0,0,0-3.26-6.93,37.27,37.27,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.6-2.23-1.05-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.16,41.16,0,0,0,6.79,9.29,36.46,36.46,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.88,20.88,0,0,0,5.59.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M247.68,261.12a20.88,20.88,0,0,0-5.59.51,22.78,22.78,0,0,0-7,3.05,36.25,36.25,0,0,0-7.1,5.62,41.32,41.32,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.78-.75,3-1.35a41.26,41.26,0,0,0,9.5-6.52,36.6,36.6,0,0,0,5.84-6.92,23.15,23.15,0,0,0,3.26-6.92,20.61,20.61,0,0,0,.67-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M267.07,270.3a36.62,36.62,0,0,0-7.09-5.62,22.85,22.85,0,0,0-7-3.05,20.84,20.84,0,0,0-5.58-.51l.08.06a20.69,20.69,0,0,0,.68,5.56,23.16,23.16,0,0,0,3.25,6.92,37,37,0,0,0,5.84,6.92,41.77,41.77,0,0,0,9.5,6.52c1.2.6,2.24,1,3,1.35h5.15v-6.88c-.28-.6-.58-1.19-1-2A41.56,41.56,0,0,0,267.07,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="179.18"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M274.87,291.11h-5.15c-.75.31-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37,37,0,0,0-5.84,6.92,23.16,23.16,0,0,0-3.25,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.3,41.3,0,0,0,6.8-9.3c.39-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M247.59,318.39a20.64,20.64,0,0,0-.67-5.57,23.15,23.15,0,0,0-3.26-6.92,36.6,36.6,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.61-2.23-1.05-3-1.36h-5.16V298c.28.58.61,1.25,1,2a41.06,41.06,0,0,0,6.79,9.3,36.46,36.46,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.47,20.47,0,0,0,5.59.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M247.68,318.47a20.88,20.88,0,0,0-5.59.51,23,23,0,0,0-7,3,36.46,36.46,0,0,0-7.1,5.63,41.32,41.32,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.78-.75,3-1.36a41,41,0,0,0,9.5-6.51,36.6,36.6,0,0,0,5.84-6.92,23.15,23.15,0,0,0,3.26-6.92,20.64,20.64,0,0,0,.67-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M267.07,327.65A36.84,36.84,0,0,0,260,322a23.1,23.1,0,0,0-7-3,20.84,20.84,0,0,0-5.58-.51l.08,0a20.71,20.71,0,0,0,.68,5.57,23.16,23.16,0,0,0,3.25,6.92,37,37,0,0,0,5.84,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1.06,3,1.36h5.15v-6.88c-.28-.6-.58-1.19-1-2A41.56,41.56,0,0,0,267.07,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="179.18"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M274.87,348.46h-5.15c-.75.3-1.79.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37,37,0,0,0-5.84,6.92,23.16,23.16,0,0,0-3.25,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3,36.84,36.84,0,0,0,7.09-5.63,41.56,41.56,0,0,0,6.8-9.3c.39-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M247.59,375.74a20.64,20.64,0,0,0-.67-5.57,23.15,23.15,0,0,0-3.26-6.92,36.6,36.6,0,0,0-5.84-6.92,41,41,0,0,0-9.5-6.51c-1.2-.61-2.23-1.06-3-1.36h-5.16v6.87c.28.58.61,1.25,1,2a41.32,41.32,0,0,0,6.79,9.3,36.46,36.46,0,0,0,7.1,5.63,23,23,0,0,0,7,3,20.47,20.47,0,0,0,5.59.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M247.68,375.82a20.47,20.47,0,0,0-5.59.51,23,23,0,0,0-7,3A36.46,36.46,0,0,0,228,385a41.06,41.06,0,0,0-6.79,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.31,1.78-.75,3-1.36a41,41,0,0,0,9.5-6.51,36.6,36.6,0,0,0,5.84-6.92,23.15,23.15,0,0,0,3.26-6.92,20.64,20.64,0,0,0,.67-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M267.07,385a36.84,36.84,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.43,20.43,0,0,0-5.58-.51l.08.05a20.71,20.71,0,0,0,.68,5.57,23.16,23.16,0,0,0,3.25,6.92,37,37,0,0,0,5.84,6.92,41.52,41.52,0,0,0,9.5,6.51c1.2.61,2.24,1,3,1.36h5.15v-6.89c-.28-.6-.58-1.19-1-2A41.3,41.3,0,0,0,267.07,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
          <g>
            <g>
              <rect
                x="121.49"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M217.18,233.76H212c-.75.31-1.78.76-3,1.36a41.27,41.27,0,0,0-9.5,6.51,37.27,37.27,0,0,0-5.84,6.92,23,23,0,0,0-3.25,6.93,20.29,20.29,0,0,0-.68,5.56l-.08.06a20.84,20.84,0,0,0,5.58-.51,22.92,22.92,0,0,0,7-3,36.46,36.46,0,0,0,7.1-5.63,41.4,41.4,0,0,0,6.8-9.29c.39-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M189.9,261a20.69,20.69,0,0,0-.68-5.56,23.22,23.22,0,0,0-3.25-6.93,37.71,37.71,0,0,0-5.84-6.92,41.27,41.27,0,0,0-9.5-6.51c-1.2-.6-2.23-1.05-3-1.36h-5.16v6.87c.27.58.61,1.25,1,2a41.4,41.4,0,0,0,6.8,9.29,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.84,20.84,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M190,261.12a20.84,20.84,0,0,0-5.58.51,22.85,22.85,0,0,0-7,3.05,36.62,36.62,0,0,0-7.09,5.62,41.56,41.56,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.78-.75,3-1.35a41.51,41.51,0,0,0,9.5-6.52,37,37,0,0,0,5.84-6.92,23.16,23.16,0,0,0,3.25-6.92,20.69,20.69,0,0,0,.68-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M209.38,270.3a36.25,36.25,0,0,0-7.1-5.62,22.68,22.68,0,0,0-7-3.05,20.84,20.84,0,0,0-5.58-.51l.08.06a20.29,20.29,0,0,0,.68,5.56,22.91,22.91,0,0,0,3.25,6.92,36.6,36.6,0,0,0,5.84,6.92,41.51,41.51,0,0,0,9.5,6.52c1.2.6,2.23,1,3,1.35h5.16v-6.88c-.28-.6-.58-1.19-1-2A41.56,41.56,0,0,0,209.38,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="121.49"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M217.18,291.11H212c-.75.31-1.78.75-3,1.36a41.27,41.27,0,0,0-9.5,6.51,36.6,36.6,0,0,0-5.84,6.92,22.91,22.91,0,0,0-3.25,6.92,20.31,20.31,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,22.92,22.92,0,0,0,7-3,36.46,36.46,0,0,0,7.1-5.63,41.3,41.3,0,0,0,6.8-9.3c.39-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M189.9,318.39a20.71,20.71,0,0,0-.68-5.57A23.16,23.16,0,0,0,186,305.9a37,37,0,0,0-5.84-6.92,41.27,41.27,0,0,0-9.5-6.51c-1.2-.61-2.23-1.05-3-1.36h-5.16V298c.27.58.61,1.25,1,2a41.3,41.3,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M190,318.47a20.84,20.84,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41.56,41.56,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.78-.75,3-1.36a41.27,41.27,0,0,0,9.5-6.51A37,37,0,0,0,186,331a23.16,23.16,0,0,0,3.25-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M209.38,327.65a36.46,36.46,0,0,0-7.1-5.63,22.92,22.92,0,0,0-7-3,20.84,20.84,0,0,0-5.58-.51l.08,0a20.31,20.31,0,0,0,.68,5.57A22.91,22.91,0,0,0,193.7,331a36.6,36.6,0,0,0,5.84,6.92,41.27,41.27,0,0,0,9.5,6.51c1.2.61,2.23,1.06,3,1.36h5.16v-6.88c-.28-.6-.58-1.19-1-2A41.56,41.56,0,0,0,209.38,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="121.49"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M217.18,348.46H212c-.75.3-1.78.75-3,1.36a41.27,41.27,0,0,0-9.5,6.51,36.6,36.6,0,0,0-5.84,6.92,22.91,22.91,0,0,0-3.25,6.92,20.31,20.31,0,0,0-.68,5.57l-.08.05a20.43,20.43,0,0,0,5.58-.51,22.92,22.92,0,0,0,7-3,36.46,36.46,0,0,0,7.1-5.63,41.56,41.56,0,0,0,6.8-9.3c.39-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M189.9,375.74a20.71,20.71,0,0,0-.68-5.57,23.16,23.16,0,0,0-3.25-6.92,37,37,0,0,0-5.84-6.92,41.27,41.27,0,0,0-9.5-6.51c-1.2-.61-2.23-1.06-3-1.36h-5.16v6.87c.27.58.61,1.25,1,2a41.56,41.56,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M190,375.82a20.43,20.43,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41.3,41.3,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87h5.16c.75-.31,1.78-.75,3-1.36a41.27,41.27,0,0,0,9.5-6.51,37,37,0,0,0,5.84-6.92,23.16,23.16,0,0,0,3.25-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M209.38,385a36.46,36.46,0,0,0-7.1-5.63,22.92,22.92,0,0,0-7-3,20.43,20.43,0,0,0-5.58-.51l.08.05a20.31,20.31,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.25,6.92,36.6,36.6,0,0,0,5.84,6.92,41.27,41.27,0,0,0,9.5,6.51c1.2.61,2.23,1,3,1.36h5.16v-6.89c-.28-.6-.58-1.19-1-2A41.3,41.3,0,0,0,209.38,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <rect
                x="63.8"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M159.49,233.76h-5.16c-.75.31-1.78.76-3,1.36a41,41,0,0,0-9.5,6.51,37.27,37.27,0,0,0-5.84,6.92,23.22,23.22,0,0,0-3.26,6.93,21.11,21.11,0,0,0-.68,5.56l-.08.06a20.88,20.88,0,0,0,5.59-.51,23,23,0,0,0,7-3,36.77,36.77,0,0,0,7.1-5.63,41.49,41.49,0,0,0,6.79-9.29c.4-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M132.21,261a20.69,20.69,0,0,0-.68-5.56,23,23,0,0,0-3.26-6.93,37,37,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.6-2.24-1.05-3-1.36H104.8v6.87c.27.58.61,1.25,1,2a41.07,41.07,0,0,0,6.8,9.29,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.84,20.84,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M132.29,261.12a20.84,20.84,0,0,0-5.58.51,22.85,22.85,0,0,0-7,3.05,36.62,36.62,0,0,0-7.09,5.62,41.24,41.24,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87H110c.75-.3,1.79-.75,3-1.35a41.77,41.77,0,0,0,9.5-6.52,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.69,20.69,0,0,0,.68-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M151.69,270.3a36.56,36.56,0,0,0-7.1-5.62,22.78,22.78,0,0,0-7-3.05,20.88,20.88,0,0,0-5.59-.51l.08.06a21.11,21.11,0,0,0,.68,5.56,23.15,23.15,0,0,0,3.26,6.92,36.6,36.6,0,0,0,5.84,6.92,41.26,41.26,0,0,0,9.5,6.52c1.2.6,2.23,1,3,1.35h5.16v-6.88c-.28-.6-.59-1.19-1-2A41.65,41.65,0,0,0,151.69,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="63.8"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M159.49,291.11h-5.16c-.75.31-1.78.75-3,1.36a41,41,0,0,0-9.5,6.51A36.6,36.6,0,0,0,136,305.9a23.15,23.15,0,0,0-3.26,6.92,21.13,21.13,0,0,0-.68,5.57l-.08.05a20.47,20.47,0,0,0,5.59-.51,23,23,0,0,0,7-3,36.77,36.77,0,0,0,7.1-5.63,41.39,41.39,0,0,0,6.79-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M132.21,318.39a20.71,20.71,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92,36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.05-3-1.36H104.8V298c.27.58.61,1.25,1,2a41,41,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M132.29,318.47a20.84,20.84,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41.24,41.24,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87H110c.75-.3,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M151.69,327.65a36.77,36.77,0,0,0-7.1-5.63,23,23,0,0,0-7-3,20.88,20.88,0,0,0-5.59-.51l.08,0a21.13,21.13,0,0,0,.68,5.57A23.15,23.15,0,0,0,136,331a36.6,36.6,0,0,0,5.84,6.92,41,41,0,0,0,9.5,6.51c1.2.61,2.23,1.06,3,1.36h5.16v-6.88c-.28-.6-.59-1.19-1-2A41.65,41.65,0,0,0,151.69,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="63.8"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M159.49,348.46h-5.16c-.75.3-1.78.75-3,1.36a41,41,0,0,0-9.5,6.51,36.6,36.6,0,0,0-5.84,6.92,23.15,23.15,0,0,0-3.26,6.92,21.13,21.13,0,0,0-.68,5.57l-.08.05a20.47,20.47,0,0,0,5.59-.51,23,23,0,0,0,7-3,36.77,36.77,0,0,0,7.1-5.63,41.65,41.65,0,0,0,6.79-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M132.21,375.74a20.71,20.71,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92,36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.06-3-1.36H104.8v6.87c.27.58.61,1.25,1,2a41.24,41.24,0,0,0,6.8,9.3,36.84,36.84,0,0,0,7.09,5.63,23.1,23.1,0,0,0,7,3,20.43,20.43,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M132.29,375.82a20.43,20.43,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3A36.84,36.84,0,0,0,112.6,385a41,41,0,0,0-6.8,9.3c-.39.73-.73,1.4-1,2v6.87H110c.75-.31,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M151.69,385a36.77,36.77,0,0,0-7.1-5.63,23,23,0,0,0-7-3,20.47,20.47,0,0,0-5.59-.51l.08.05a21.13,21.13,0,0,0,.68,5.57,23.15,23.15,0,0,0,3.26,6.92,36.6,36.6,0,0,0,5.84,6.92,41,41,0,0,0,9.5,6.51c1.2.61,2.23,1,3,1.36h5.16v-6.89c-.28-.6-.59-1.19-1-2A41.39,41.39,0,0,0,151.69,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
          <g>
            <g>
              <rect
                x="6.1"
                y="198.58"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M101.8,233.76H96.64c-.75.31-1.78.76-3,1.36a41.52,41.52,0,0,0-9.5,6.51,37,37,0,0,0-5.83,6.92,23,23,0,0,0-3.26,6.93,20.69,20.69,0,0,0-.68,5.56l-.08.06a20.81,20.81,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3A36.52,36.52,0,0,0,94,251.91a41.07,41.07,0,0,0,6.8-9.29c.4-.74.73-1.41,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M74.52,261a20.69,20.69,0,0,0-.68-5.56,23,23,0,0,0-3.26-6.93,37,37,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.6-2.24-1.05-3-1.36H47.1v6.87c.28.58.61,1.25,1,2a41.07,41.07,0,0,0,6.8,9.29A36.84,36.84,0,0,0,62,257.54a23.1,23.1,0,0,0,7,3,20.81,20.81,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M74.6,261.12a20.81,20.81,0,0,0-5.58.51,22.85,22.85,0,0,0-7,3.05,36.62,36.62,0,0,0-7.09,5.62,41.24,41.24,0,0,0-6.8,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.79-.75,3-1.35a41.77,41.77,0,0,0,9.5-6.52,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.69,20.69,0,0,0,.68-5.56Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M94,270.3a36.3,36.3,0,0,0-7.09-5.62,22.85,22.85,0,0,0-7-3.05,20.81,20.81,0,0,0-5.58-.51l.08.06a20.69,20.69,0,0,0,.68,5.56,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.77,41.77,0,0,0,9.5,6.52c1.21.6,2.24,1,3,1.35h5.16v-6.88c-.29-.6-.59-1.19-1-2A41.24,41.24,0,0,0,94,270.3Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="6.1"
                y="255.92"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M101.8,291.11H96.64c-.75.31-1.78.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,36.39,36.39,0,0,0-5.83,6.92,22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.4,20.4,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3A36.52,36.52,0,0,0,94,309.26a41,41,0,0,0,6.8-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M74.52,318.39a20.71,20.71,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92A36.39,36.39,0,0,0,64.75,299a41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.05-3-1.36H47.1V298c.28.58.61,1.25,1,2a41,41,0,0,0,6.8,9.3A36.84,36.84,0,0,0,62,314.89a23.1,23.1,0,0,0,7,3,20.4,20.4,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M74.6,318.47A20.81,20.81,0,0,0,69,319a23.1,23.1,0,0,0-7,3,36.84,36.84,0,0,0-7.09,5.63,41.24,41.24,0,0,0-6.8,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.3,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51A36.39,36.39,0,0,0,70.58,331a22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M94,327.65A36.52,36.52,0,0,0,86.9,322a23.1,23.1,0,0,0-7-3,20.81,20.81,0,0,0-5.58-.51l.08,0a20.71,20.71,0,0,0,.68,5.57A22.91,22.91,0,0,0,78.32,331a36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.21.61,2.24,1.06,3,1.36h5.16v-6.88c-.29-.6-.59-1.19-1-2A41.24,41.24,0,0,0,94,327.65Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
            <g>
              <rect
                x="6.1"
                y="313.27"
                width="54.69"
                height="54.69"
                fill="#70747a"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M101.8,348.46H96.64c-.75.3-1.78.75-3,1.36a41.52,41.52,0,0,0-9.5,6.51,36.39,36.39,0,0,0-5.83,6.92,22.91,22.91,0,0,0-3.26,6.92,20.71,20.71,0,0,0-.68,5.57l-.08.05a20.4,20.4,0,0,0,5.58-.51,23.1,23.1,0,0,0,7-3A36.52,36.52,0,0,0,94,366.61a41.24,41.24,0,0,0,6.8-9.3c.4-.73.73-1.4,1-2Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M74.52,375.74a20.71,20.71,0,0,0-.68-5.57,22.91,22.91,0,0,0-3.26-6.92,36.39,36.39,0,0,0-5.83-6.92,41.52,41.52,0,0,0-9.5-6.51c-1.2-.61-2.24-1.06-3-1.36H47.1v6.87c.28.58.61,1.25,1,2a41.24,41.24,0,0,0,6.8,9.3A36.84,36.84,0,0,0,62,372.24a23.1,23.1,0,0,0,7,3,20.4,20.4,0,0,0,5.58.51Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M74.6,375.82a20.4,20.4,0,0,0-5.58.51,23.1,23.1,0,0,0-7,3A36.84,36.84,0,0,0,54.91,385a41,41,0,0,0-6.8,9.3c-.4.73-.73,1.4-1,2v6.87h5.16c.75-.31,1.79-.75,3-1.36a41.52,41.52,0,0,0,9.5-6.51,36.39,36.39,0,0,0,5.83-6.92,22.91,22.91,0,0,0,3.26-6.92,20.71,20.71,0,0,0,.68-5.57Z"
                transform="translate(-41 -35.19)"
                fill="#c7c8ca"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
              <path
                d="M94,385a36.52,36.52,0,0,0-7.09-5.63,23.1,23.1,0,0,0-7-3,20.4,20.4,0,0,0-5.58-.51l.08.05a20.71,20.71,0,0,0,.68,5.57,22.91,22.91,0,0,0,3.26,6.92,36.39,36.39,0,0,0,5.83,6.92,41.52,41.52,0,0,0,9.5,6.51c1.21.61,2.24,1,3,1.36h5.16v-6.89c-.29-.6-.59-1.19-1-2A41,41,0,0,0,94,385Z"
                transform="translate(-41 -35.19)"
                fill="#a4a2a5"
                stroke="#555b64"
                strokeMiterlimit="10"
                strokeWidth="0.5"
              />
            </g>
          </g>
        </g>
      </g>
    </React.Fragment>
  )
}
